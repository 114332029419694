<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
            {{ $t("message.new_incoming_payment") }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
          <el-form ref="form" :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item prop="staff_id">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                    <span class="start_top">*</span>
                    {{ $t("message.employees") }}
                  </span>
                  <select-staff
                    :id="form.staff_id"
                    v-model="form.staff_id"
                  ></select-staff>
                </el-form-item>                
                <el-form-item prop="currency_id">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                    <span class="start_top">*</span>
                    {{ $t("message.currency") }}
                  </span>
                  <select-currency
                    :id="form.currency_id"
                    v-model="form.currency_id"
                  ></select-currency>
                </el-form-item>                
                <el-form-item prop="date_of_end">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                    <span class="start_top">*</span>
                    {{ $t("message.salary") }} {{ $t("message.datas") }}
                  </span>
                  <el-date-picker
                  :class="mode ? 'input__day' : 'input__night'"
                    class="w-100"
                    size="medium"
                    v-model="form.date_of_end"
                    type="date"
                    :picker-options="startAndEndMonthsOptions"
                    :format="'dd.MM.yyyy'"
                    :value-format="'dd.MM.yyyy'"
                    :placeholder="
                      $t('message.salary') + ' ' + $t('message.datas')
                    "
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item prop="payment_month">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24 title_data">
                    <span class="start_top">*</span>
                    {{ $t("message.payment_for") }}
                  </span>
                  <el-date-picker
                  :class="mode ? 'input__day' : 'input__night'"
                    class="w-100"
                    size="medium"
                    v-model="form.payment_month"
                    type="month"
                    :format="'MMMM yyyy'"
                    :value-format="'dd.MM.yyyy'"
                    :picker-options="paymentMonthsOptions"
                    :placeholder="$t('message.payment_for')"
                  >
                  </el-date-picker>
                </el-form-item>
               
                 <el-form-item prop="account_id">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                    <span class="start_top">*</span>
                    {{ $t("message.check") }}
                  </span>
                  <select-account
                    :currency_id="form.currency_id"
                    :id="form.account_id"
                    v-model="form.account_id"
                  ></select-account>
                </el-form-item>               
                <el-form-item prop="paid_amount">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                    <span class="start_top">*</span>
                    {{ $t("message.amount") }}
                  </span>
                  <crm-input
                  :class="mode ? 'input__day' : 'input__night'"
                    :inputValue="form.paid_amount"
                    :placeholder="$t('message.amount')"
                    v-model="form.paid_amount"
                    size="medium"
                    :type="'number'"
                  ></crm-input>
                </el-form-item>
              </el-col>

              <el-col :span="8">               
                <el-form-item prop="payment_type">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                    <span class="start_top">*</span>
                    {{ $t("message.payment_type") }}
                  </span>
                  <el-select
                    class="w-100"
                    v-model="form.payment_type"
                    size="medium"
                    :placeholder="$t('message.payment_type')"
                    :class="mode ? 'input__day' : 'input__night'"
                  >
                    <el-option
                      v-for="(type, index) in payment_types"
                      :key="'payment_types' + index"
                      :label="type.label"
                      :value="type.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="salary_type">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                    <span class="start_top">*</span>
                    {{ $t("message.salary_type") }}
                  </span>
                  <el-select
                    class="w-100"
                    v-model="form.salary_type"
                    size="medium"
                    :placeholder="$t('message.salary_type')"
                    :class="mode ? 'input__day' : 'input__night'"
                  >
                    <el-option
                      v-for="(salary, index) in salary_types"
                      :key="'salary-' + index"
                      :label="salary.label"
                      :value="salary.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row
              :gutter="20"
              v-if="form.payment_type == 'card'"
              v-loading="loadCard"
            >
              <el-col :span="8">
                <el-form-item>
                  <span
                    class="input--label d-block mb-0 line-h-24 title_data"
                    >{{ $t("message.bank") }}</span
                  >
                  <el-select
                    class="w-100"
                    v-model="form.card_id"
                    size="medium"
                    :placeholder="$t('message.bank')"
                    @change="changeCard"
                  >
                    <el-option
                      v-for="(card, index) in staffCards"
                      :key="'card-' + index"
                      :label="card.card_authority"
                      :value="card.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24 title_data">{{
                  $t("message.card_number")
                }}</span>
               
                  <crm-input
                  :class="mode ? 'input__day' : 'input__night'"
                    size="medium"
                    :disabled="true"
                    :inputValue="form.card ? form.card.card_number : ''"
                    v-model="form.card_number"
                  ></crm-input>
                
              </el-col>
              <el-col :span="8">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24 title_data">
                  {{ $t("message.expiration_date") }}
                </span>
               
                  <crm-input
                  :class="mode ? 'input__day' : 'input__night'"
                    size="medium"
                    :disabled="true"
                    :inputValue="form.card ? form.card.expiry_date : ''"
                    v-model="form.card_expiry_date"
                  ></crm-input>
                
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <el-form-item>
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                    {{ $t("message.comment") }}
                  </span>
                  <el-input :class="mode ? 'input__day' : 'input__night'" type="textarea" v-model="form.comment"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            
            <div v-if="form.bonuses">
              <h4  class="block_title" :class="mode ? 'table__myday' : 'table__mynight'">{{$t('message.bonuses')}}</h4>
              <table class="table-my-code table-bordered w-100">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">T/r</th>
                    <th scope="col">Название бонуса</th>
                    <th scope="col">Дата создания</th>
                    <th scope="col" style="width: 30%">Причина</th>
                    <th scope="col">Сумма</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(bonus, index) in form.bonuses"
                    :key="'bonus-' + index"
                  >
                  <td>
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      v-model="slelectedBonuses"
                      :value="bonus"
                      @change="handleCheckedItemsChange"
                    />
                  </td>
                    <th scope="row">{{ index+1 }}</th>
                    <td>
                      {{ bonus.name }}
                    </td>
                    <td>
                      {{ bonus.created_at }}
                    </td>
                    <td>
                      {{ bonus.reason ? bonus.reason.name : "" }}
                    </td>
                    <td>
                      {{ parseFloat(bonus.amount).toLocaleString("ru-RU") }}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <td scope="col" colspan="5">Итого:</td>
                  <td scope="col">
                    <b>{{ parseFloat(form.bonus_amount).toLocaleString("ru-RU") }}</b>
                  </td>
                </tfoot>
              </table>
              <br>
            </div>
            <div v-if="form.penalties">
              <h4  class="block_title">{{$t('message.penalties')}}</h4>
              <table class="table-my-code table-bordered w-100">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">T/r</th>
                    <th scope="col">Название штрафа</th>
                    <th scope="col">Дата создания</th>
                    <th scope="col" style="width: 30%">Причина</th>
                    <th scope="col">Сумма</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(penalty, index) in form.penalties"
                    :key="'penalty-' + index"
                  >
                  <td>
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      v-model="slelectedPenalties"
                      :value="penalty"
                      @change="handleCheckedItemsChange"
                    />
                  </td>
                    <th scope="row">{{ index+1 }}</th>
                    <td>
                      {{ penalty.name }}
                    </td>
                    <td>
                      {{ penalty.created_at }}
                    </td>
                    <td>
                      {{ penalty.reason ? penalty.reason.name : "" }}
                    </td>
                    <td>
                      {{ parseFloat(penalty.amount).toLocaleString("ru-RU")  }}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <th scope="col" colspan="5">Итого:</th>
                  <th scope="col">
                    <b>{{  parseFloat(form.penalty_amount).toLocaleString("ru-RU")}}</b>
                  </th>
                </tfoot>
              </table>
              <br>
            </div>
            <!-- <div>
              <table class="table-my-code table-bordered w-100">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Дата создания</th>
                    <th scope="col" style="width: 30%">Причина</th>
                    <th scope="col">Сумма</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(advance, index) in form.advances"
                    :key="'advance-' + index"
                  >
                    <th scope="row">{{ advance.id }}</th>
                    <td>
                      {{ advance.created_at }}
                    </td>
                    <td>
                      {{ advance.comment }}
                    </td>
                    <td>
                      {{ advance.amount }}
                    </td>
                    
                  </tr>
                </tbody>
                <tfoot>
                  <th scope="col" colspan="3">Итого:</th>
                  <th scope="col">
                    <b>{{ form.advance_amount }}</b>
                  </th>
                </tfoot>
              </table>
              <br>
            </div> -->
            <div v-if="form.transactions">
              <h4 class="block_title">Transaction</h4>
              <table class="table-my-code table-bordered w-100" >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{$t('message.salary_type')}}</th>
                    <th scope="col">{{$t('message.payment_for')}}</th>
                    <th scope="col">Дата создания</th>
                    <th scope="col" style="width: 30%">Причина</th>
                    <th scope="col">Сумма</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(transaction, index) in form.transactions"
                    :key="'advance-' + index"
                  >
                    <th scope="row">{{ index+1 }}</th>
                    <th scope="row">{{ $t('message.'+transaction.salary_type)  }}</th>
                    <th scope="row">{{ getMounth(transaction.payment_month) }}</th>
                    <td>
                      {{ transaction.created_at }}
                    </td>
                    <td>
                      {{ transaction.comment }}
                    </td>
                    <td>
                      {{parseFloat(transaction.paid_amount ).toLocaleString("ru-RU") }}
                    </td>
                    
                  </tr>
                </tbody>
                <tfoot>
                  <th scope="col" colspan="5">Итого:</th>
                  <th scope="col">
                    <b>{{ parseFloat(form.transaction_amount).toLocaleString("ru-RU") }}</b>
                  </th>
                </tfoot>
              </table>
              <br>
            </div>
           
          </el-form>
        </div>

        <div class="timeline-items__right rounded-sm mt-5 w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
          <el-row :gutter="20">
            <el-col :span="5">
              <div class="card-payment-itme">
                <div class="card-payment-itme-title">
                  {{ $t("message.salary") }}
                </div>
                <div class="card-payment-itme-summa mt-1">
                  <b>{{ form.salary_amount ? parseFloat(form.salary_amount).toLocaleString("ru-RU") : 0 }}</b>
                </div>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="card-payment-itme">
                <div class="card-payment-itme-title">
                  {{ $t("message.real_amount") }}
                </div>
                <div class="card-payment-itme-summa mt-1">
                  <b>{{ form.paid_amount ?  parseFloat(form.paid_amount).toLocaleString("ru-RU") : 0 }}</b>
                </div>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="card-payment-itme">
                <div class="card-payment-itme-title">
                  {{ $t("message.bonus_amount") }}
                </div>
                <div class="card-payment-itme-summa mt-1">
                  <b>{{ form.bonus_amount ? parseFloat(form.bonus_amount).toLocaleString("ru-RU") : 0 }}</b>
                </div>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="card-payment-itme">
                <div class="card-payment-itme-title">
                  {{ $t("message.penalties") }}
                </div>
                <div class="card-payment-itme-summa mt-1">
                  <b>{{ form.penalty_amount ? parseFloat(form.penalty_amount).toLocaleString("ru-RU") : 0 }}</b>
                </div>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="card-payment-itme">
                <div class="card-payment-itme-title">
                  Transactions
                </div>
                <div class="card-payment-itme-summa mt-1">
                  <b>{{ form.transaction_amount ? parseFloat(form.transaction_amount).toLocaleString("ru-RU") : 0 }}</b>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="timeline-items__right rounded-sm mt-5 w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
          <el-row :gutter="20">
            <el-col :span="5">
              <div class="card-payment-itme">
                <div class="card-payment-itme-title">
                  {{ $t("message.salary") }} {{ $t("message.datas") }}
                </div>
                <div class="card-payment-itme-summa mt-1">
                  <b>{{ form.salary ? form.salary.date_of_start : "" }}</b>
                </div>
              </div>
            </el-col>

            <el-col :span="5">
              <div class="card-payment-itme">
                <div class="card-payment-itme-title">
                  {{ $t("message.salary") }} {{ $t("message.datas") }}
                </div>
                <div class="card-payment-itme-summa mt-1">
                  <b>{{ form.date_of_end }}</b>
                </div>
              </div>
            </el-col>

            <el-col :span="8" :offset="16">
              <div class="card-payment-itme">
                <div class="card-payment-itme-title" style="font-size: 20px">
                  {{ $t("message.total") }} {{ $t("message.on_hands") }}
                </div>
                <div
                  class="card-payment-itme-summa mt-1"
                  style="font-size: 20px"
                >
                  <b>{{
                    form.salary_amount +
                    "+" +
                    form.bonus_amount +
                    "-" +
                    form.penalty_amount +
                    "-" +
                    form.transaction_amount+
                    "=" +
                    parseFloat(form.paid_amount).toLocaleString("ru-RU")
                  }}</b>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import SelectStaff from "@/components/filters/inventory/select-staff";
import SelectCurrency from "@/components/filters/inventory/select-currency";
import SelectAccount from "@/components/filters/inventory/select-account";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import { i18n } from "@/utils/i18n";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [drawer, form],
  components: {
    SelectStaff,
    SelectCurrency,
    SelectAccount,
  },
  data() {
    return {
      changeMultipleStatus: [],
      slelectedPenalties:[],
      slelectedBonuses:[],
      loadingButton: false,
      form: {
        penalty_amount: 0,
        penalty_amount: 0,
        bonus_amount: 0,
        advance_amount: 0,
        salary_amount: 0,
        transaction_amount: 0,
      },
      payment_types: [
        { value: "card", label: i18n.t("message.Пластик")},
        { value: "cash", label: i18n.t("message.Наличный") },
      ],
      salary_types: [
        { value: "salary", label: i18n.t("message.salary") },
        // { value: "additional_salary", label: "Сверхурочные" },
      ],
      loadCard: false,
      penalty_amount: 0,
      bonus_amount: 0,
    };
  },
  watch: {
    "form.payment_type": {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          if (this.form.payment_type == "card") {
            this.getCard();
          }
        }
      },
      deep: true,
    },
    "form.staff_id": {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          if (this.form.payment_type == "card") {
            this.getCard();
          }
          this.requestInOrder();
        }
      },
      deep: true,
    },
    "form.payment_month": {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          if (this.form.payment_type == "card") {
            this.getCard();
            this.calculateSalary();
          }
          this.requestInOrder();
        }
      },
      deep: true,
    },
    "form.card": {
      handler: function () {},
      deep: true,
    },
    "form.date_of_end": {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.calculateSalary();
        }
      },
      deep: true,
    },
    "form.paid_amount": {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.calculateSalary();
        }
      },
      deep: true,
    },
    staffStartAndEndDates: {
      handler: function () {},
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      staffCards: "staffTransaction/cards",
      staffSalary: "staffTransaction/salary",
      staffGraphic: "staffTransaction/graphic",
      staffPenalties: "staffTransaction/penalties",
      staffBonuses: "staffTransaction/bonuses",
      staffAdvance: "staffTransaction/advances",
      staffTransactions: "staffTransaction/transactions",
      staffPaymentMonths: "staffTransaction/payment_months",
      staffStartAndEndDates: "staffTransaction/start_and_end_dates",
      rules: "transaction/rules",
      mode: "MODE"
    }),
    paymentMonthsOptions: function () {
      let paidMonths = this.staffPaymentMonths;
      return {
        disabledDate(time) {
          var date = time.getMonth() + 1 + "." + time.getFullYear();
          if (paidMonths.includes(date)) {
            return true;
          }
          return false;
        },
      };
    },
    startAndEndMonthsOptions: function () {
      let startAndEndMonths = this.staffStartAndEndDates;
      return {
        disabledDate(time) {
          var month = time.getMonth() + 1;
          var date = time.getDate() + "." + month + "." + time.getFullYear();
          if (startAndEndMonths.includes(date)) {
            return true;
          }
          return false;
        },
      };
    },
  },
  methods: {
    ...mapActions({
      getStaffCard: "staffTransaction/getCard",
      getStaffPenalty: "staffTransaction/getPenalty",
      getStaffBonus: "staffTransaction/getBonus",
      getStaffAdvance: "staffTransaction/getAdvance",
      getStaffSalary: "staffTransaction/getSalary",
      getStaffGraphic: "staffTransaction/getGraphic",
      getSalaryWork: "staffTransaction/getSalaryToWorkHours",
      getStaffTransaction: "staffTransaction/getTransaction",
      save: "transaction/store",
      empty: "transaction/empty",
    }),
    async requestInOrder() {
      await this.getPenalties();
      await this.getSalaryWorkAll();
      await this.getBonuses();
      await this.getTransactions();
      // await this.getAdvance();
      await this.getSalary();
      await this.getGraphic();
      await this.calculateSalary();
    },
    async getGraphic() {
      if (this.form.staff_id) {
        await this.getStaffGraphic(this.form.staff_id)
          .then((res) => {
            this.form.graphic = JSON.parse(JSON.stringify(this.staffGraphic));
          })
          .catch((err) => {
            this.$alert(err);
          });
      }
    },
    async getSalaryWorkAll() {
      if (this.form.staff_id) {
        let params ={
          'staff_id':this.form.staff_id,
          'date':this.form.payment_month,
        }
        await this.getSalaryWork(params)
          .then((res) => {
            this.form.work_salary = res.data.result.data.work_salary;
          })
          .catch((err) => {
            this.$alert(err);
          });
      }
    },
    async getSalary() {
      if (this.form.staff_id) {
        await this.getStaffSalary(this.form.staff_id)
          .then((res) => {
            this.form.salary = JSON.parse(JSON.stringify(this.staffSalary));
            if (this.form.salary) {
              this.form.salary_id = this.form.salary.id;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async getCard() {
      if (this.form.staff_id && !this.loadCard) {
        this.loadCard = true;
        await this.getStaffCard(this.form.staff_id)
          .then((res) => {
            this.loadCard = false;
          })
          .catch((err) => {
            this.loadCard = false;
            this.$alert(err);
          });
      }
    },
    async getPenalties() {
      if (this.form.staff_id) {
        await this.getStaffPenalty(this.form.staff_id)
          .then((res) => {
            this.form.penalties = JSON.parse(
              JSON.stringify(this.staffPenalties)
            );
            this.calculatePenalty();
          })
          .catch((err) => {
            this.$alert(err);
          });
      }
    },
    async getBonuses() {
      if (this.form.staff_id) {
        await this.getStaffBonus(this.form.staff_id)
          .then((res) => {
            this.form.bonuses = JSON.parse(
              JSON.stringify(this.staffBonuses)
            );
            this.calculateBonus();
          })
          .catch((err) => {
            this.$alert(err);
          });
      }
    },
    async getTransactions() {
      if (this.form.staff_id && this.form.payment_month) {
        let param = {
          'staff_id':this.form.staff_id,
          'payment_month':this.form.payment_month,
        }
        await this.getStaffTransaction(param)
          .then((res) => {
            this.form.transactions =  res.data.result.data.transactions;
            this.calculateTransactions();

          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // async getAdvance() {
    //   if (this.form.staff_id && this.form.payment_month) {
    //     let param = {
    //       'staff_id':this.form.staff_id,
    //       'payment_month':this.form.payment_month,
    //     }
    //     await this.getStaffAdvance(param)
    //       .then((res) => {
    //         console.log(res);
    //         this.form.advances = JSON.parse(
    //           JSON.stringify(this.staffAdvance)
    //         );
    //         this.calculateAdvance();
    //       })
    //       .catch((err) => {
    //         this.$alert(err);
    //       });
    //   }
    // },
  
    calculatePenalty() {
      let penaltyList = this.slelectedPenalties;
      if (penaltyList) {
        this.form.penalty_amount = _.sumBy(penaltyList, function (o) {
          return o.amount;
        });
        this.form.penalty_ids = _.map(penaltyList, "id");
      } else {
        this.form.penalty_ids = [];
        this.form.penalty_amount = 0;
      }
    },
    calculateBonus() {
      let bonusList = this.slelectedBonuses;
      if (bonusList) {
        this.form.bonus_amount = _.sumBy(bonusList, function (o) {
          return o.amount;
        });
        this.form.bonus_ids = _.map(bonusList, "id");
      } else {
        this.form.bonus_ids = [];
        this.form.bonus_amount = 0;
      }
    },
    calculateTransactions() {
      let bonusList = this.form.transactions;
      if (bonusList) {
        this.form.transaction_amount = _.sumBy(bonusList, function (o) {
          return o.paid_amount;
        });
      } else {
        this.form.transaction_amount = 0;
      }
    },
    // calculateAdvance() {
    //   let advanceList = this.form.advances;
    //   if (advanceList&&advanceList.length) {
    //     this.form.advance_amount = _.sumBy(advanceList, function (o) {
    //       return + o.amount;
    //     });
    //     this.form.advance_ids = _.map(advanceList, "id");
    //   } else {
    //     this.form.advance_ids = [];
    //     this.form.advance_amount = 0;
    //   }
    // },
    changeCard(cardId) {
      if (this.staffCards) {
        this.form.card = JSON.parse(
          JSON.stringify(_.find(this.staffCards, ["id", cardId]))
        );
      }
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (res.status == 201) {
                this.afterLeave();
                this.close();
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },

    afterLeave() {
      this.clearForm();
    },

    clearForm() {
      this.empty();
      this.form = JSON.parse(
        JSON.stringify({
          penalty_amount: 0,
          penalty_amount: 0,
          bonus_amount:0,
          salary_amount: 0,
        })
      );
    },
    calculateSalary() {
      if (!_.isEmpty(this.form.graphic) && !_.isEmpty(this.form.salary)) {
        // let filteredWorkingDays = _.filter(this.form.graphic.schedules, [
        //   "is_working_day",
        //   true,
        // ]);

        // let workingDays = [];

        // _.forEach(filteredWorkingDays, function (value) {
        //   workingDays.push(value.day_number);
        // });

        if (!_.isEmpty(this.form.transactions)) {
          this.form.date_of_start = _.head(this.form.transactions).date_of_end;
          this.form.salary.date_of_start = this.form.date_of_start;
        } else {
          this.form.date_of_start = this.form.salary.date_of_start;
        }

        // let workedDates = this.enumerateDaysBetweenDates(
        //   this.form.salary.date_of_start,
        //   this.form.date_of_end
        // );

        // let monthsAgo = this.enumerateDaysBetweenDates(
        //   moment(this.form.date_of_end, "DD.MM.YYYY")
        //     .add(-1, "M")
        //     .format("DD.MM.YYYY"),
        //   this.form.date_of_end
        // );

        // let totalWorkingDaysInMonth = 0;

        // let totalWorkedHoursInMonth = 0;

        // monthsAgo.forEach((element) => {
        //   let numberOfDay = moment(element, "DD.MM.YYYY").day();
        //   moment(element, "DD.MM.YYYY").format("DD.MM.YYYY");

        //   if (workingDays.includes(numberOfDay)) {
        //     totalWorkingDaysInMonth += 1;
        //     let workedDay = _.find(this.form.graphic.schedules, [
        //       "day_number",
        //       numberOfDay,
        //     ]);
        //     totalWorkedHoursInMonth += parseFloat(workedDay.total_working_time);
        //   }
        // });

        // let salaryAmountPerMonth =
        //   parseFloat(this.form.salary.amount) / totalWorkedHoursInMonth;

        // let totalWorkingDays = 0;

        // let totalWorkedHours = 0;

        // workedDates.forEach((element) => {
        //   let dayNumber = moment(element, "DD.MM.YYYY").day();

        //   if (workingDays.includes(dayNumber)) {
        //     totalWorkingDays += 1;
        //     let workedDay = _.find(this.form.graphic.schedules, [
        //       "day_number",
        //       dayNumber,
        //     ]);
        //     totalWorkedHours += parseFloat(workedDay.total_working_time);
        //   }
        // });

        this.form.salary_amount = this.form.work_salary;

        let salary_amount = parseFloat(this.form.work_salary);

        let penalty_amount = parseFloat(this.form.penalty_amount);

        let bonus_amount = parseFloat(this.form.bonus_amount);

        let transaction_amount = parseFloat(this.form.transaction_amount);

        // let advance_amount = parseFloat(this.form.advance_amount);
        this.form.paid_amount = (salary_amount - penalty_amount ) + bonus_amount - transaction_amount;
        // console.log( this.form.paid_amount);
      }
    },
    enumerateDaysBetweenDates(startDate, endDate) {
      let date = [];

      while (moment(startDate, "DD.MM.YYYY") <= moment(endDate, "DD.MM.YYYY")) {
        date.push(startDate);

        startDate = moment(startDate, "DD.MM.YYYY")
          .add(1, "days")
          .format("DD.MM.YYYY");
      }

      return date;
    },
    getMounth(data){
      return  moment(data, "M.YYYY").locale('ru').format("MMMM YYYY");
    },
    paymentMonths: function () {
      return this.staffPaymentMonths;
    },

    handleCheckedItemsChange(){
      this.calculateBonus();
      this.calculatePenalty();
      this.calculateSalary();
    }
  },
};
</script>
<style lang="scss" scoped>
  .block_title{
    margin: 5px 0 5px;
  }
</style>
